<template>
  <v-dialog v-model="dialogPermissions" persistent max-width="70%" scrollable>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            Vincular usuário: <strong>{{ userData.nome }}</strong
            >, a Permissões.
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-treeview
              v-model="selection"
              :items="getPermissions"
              item-disabled="locked"
              selectable
              @input="mountUserPermissions($event)"
              return-object
              open-on-click
            >
            </v-treeview>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="pa-6" cols="6">
            <template v-if="!selection.length">
              Sem permissões vinculadas
            </template>
            <template v-else>
              <div v-for="node in selection" :key="node.id">
                {{ node.name }}
              </div>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closePermissions"
          >Fechar</v-btn
        >
        <v-btn
          color="blue darken-1"
          @click="setPermissionsToUser"
          :disabled="userData.id_papeis == 1"
          text
          >Salvar</v-btn
        >
      </v-card-actions>
      <BaseFullScreenLoading :loading="loading" loadingText="Carregando..." />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import serviceSistema from "@/services/sistema";
import BaseFullScreenLoading from "@/components/shared/BaseFullScreenLoading";
import responseHelper from "@/support/reponse";

export default {
  name: "ModalVinculoPermissao",
  components: { BaseFullScreenLoading },

  props: {
    dialogPermissions: {
      type: Boolean
    },
    userData: Object
  },

  watch: {
    dialogPermissions(value) {
      value && this.inicialize();
    }
  },

  data() {
    return {
      selection: [],
      selectedPermissions: [],
      selectedDifPermissions: [],
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      getPermissions: "sistema/getPermissions",
      getUserPermissions: "sistema/getUserPermissions"
    })
  },

  methods: {
    ...mapActions({
      fetchUser: "sistema/fetchUser",
      fetchPermissions: "sistema/fetchPermissions"
    }),

    mountUserPermissions(item) {
      this.selectedPermissions = item.filter(item => !item.locked);
    },

    async inicialize() {
      await this.fetchPermissions(this.userData.id_usuario);
      this.selection = this.getUserPermissions;
    },

    async setPermissionsToUser() {
      try {
        this.loading = true;

        await serviceSistema.postUserPermissoes(this.userData.id_usuario, {
          id_permissao: this.selectedPermissions
        });
        // tste
        this.$notify({
          type: "success",
          text: "Permissões vinculadas com sucesso"
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: responseHelper(error, "permissoes", "vincular").message
        });
      } finally {
        this.loading = false;
      }
    },

    closePermissions() {
      this.$emit("closePermissions");
      this.selection = [];
    }
  }
};
</script>

<style></style>
