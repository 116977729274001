<template>
  <v-dialog v-model="dialogDomain" max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            Vincular usuário: <strong>{{ userData.nome }}</strong
            >, a empresas.
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Empresas sem
              Vinculo
            </h4>
            <draggable
              class="draggable-area exclude-companies"
              :list="empresasNaoVinculadas"
              group="empresas"
              @change="moveAdded"
            >
              <div
                class="v-list-item__content list"
                v-for="empresa in empresasNaoVinculadas"
                :key="empresa.cnpj"
              >
                <div class="list-empresa-items">
                  <div class="list-empresa">
                    {{
                      `${empresa.apelido} - ${formatNomeEmpresa(
                        empresa.nome_empresa
                      )}`
                    }}
                    <BaseSegmento :segmento="empresa.id_band" />
                  </div>
                  <div class="mr-4">
                    <v-btn icon @click="addEmpresasParcitipantes(empresa)">
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h3 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Empresas
              Vinculadas
            </h3>
            <draggable
              class="draggable-area include-companies"
              :list="empresasVinculadas"
              group="empresas"
              @change="moveRemove"
            >
              <div
                class="v-list-item__content"
                v-for="empresa in empresasVinculadas"
                :key="empresa.id_empresa_campanha"
              >
                <div class="list-empresa-participantes-items">
                  <div>
                    <v-btn
                      class="mr-4"
                      icon
                      @click="removeEmpresasParticipantes(empresa)"
                    >
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-empresa mr-4">
                    {{
                      `${empresa.apelido} - ${formatNomeEmpresa(empresa.nome)}`
                    }}
                    <BaseSegmento :segmento="empresa.id_band" />
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closeDomain">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import serviceSistema from "@/services/sistema";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ModalVinculoEmpresa",

  components: {
    draggable,
    BaseSegmento: () => import("@/components/shared/BaseSegmento.vue")
  },

  props: {
    dialogDomain: {
      type: Boolean,
      default: false
    },
    userData: Object
  },

  watch: {
    dialogDomain(value) {
      value && this.inicialize();
    }
  },

  data() {
    return {
      empresasNaoVinculadas: [],
      empresasVinculadas: []
    };
  },

  computed: {
    ...mapGetters({
      EmpresasVinculadasAoUsuario: "sistema/getEmpresasVinculadasAoUsuario",
      EmpresasNaoVinculadasAoUsuario:
        "sistema/getEmpresasNaoVinculadasAoUsuario"
    })
  },

  methods: {
    ...mapActions({
      actionEmpresasUser: "sistema/actionEmpresasUser"
    }),

    closeDomain() {
      this.$emit("closeDomain");
    },

    ...mapMutations({
      setEmpresas: "campanha/setEmpresas"
    }),

    formatNomeEmpresa(item) {
      item = item.split("-")[0];
      return item;
    },

    async inicialize() {
      await this.actionEmpresasUser({ id_usuario: this.userData.id_usuario });
      this.empresasVinculadas = this.EmpresasVinculadasAoUsuario;
      this.empresasNaoVinculadas = this.EmpresasNaoVinculadasAoUsuario;
    },

    async addEmpresasParcitipantes(item) {
      try {
        const form = {
          id_usuario: this.userData.id_usuario,
          id_empresa: item.id_empresa
        };
        await serviceSistema.postUserEmpresas(form);
        this.$notify({
          type: "success",
          text: `${item.apelido} vinculado ao usuário`
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: ` Erro ao adicionar  ${error.item.apelido}`
        });
      } finally {
        this.inicialize();
      }
    },

    async removeEmpresasParticipantes(item) {
      try {
        await serviceSistema.deletUserEmpresas(item.id);
        this.$notify({
          type: "warning",
          text: `${item.apelido} desvinculado do usuário`
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: ` Error ao remover ${error.item.apelido} `
        });
      } finally {
        this.inicialize();
      }
    },

    moveAdded(evt) {
      let empresa = {};
      const { removed } = evt;

      if (removed) {
        empresa = removed.element;
        this.addEmpresasParcitipantes(empresa);
      }
    },

    moveRemove(evt) {
      let empresa = {};
      const { removed } = evt;

      if (removed) {
        empresa = removed.element;
        this.removeEmpresasParticipantes(empresa);
      }
    }
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-companies {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
}

.draggable-area.exclude-companies {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
}
.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-empresa-items {
  display: flex !important;
  justify-content: space-between;
}

.list-empresa-participantes-items {
  display: flex !important;
}

.list-empresa {
  display: flex !important;
  flex-direction: column;
}
</style>
