<template>
  <div>
    <BaseTable
      class="mt-4"
      :headers="headers"
      :items="infoUser"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="nome"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Usuários</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn
            v-can-access="1562"
            color="primary"
            @click="(dialog = true), (labelBtn = 'Salvar')"
            disabled
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
            Cadastrar
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.telefone`]="{ item }">
        {{ item.telefone | BrazilianPhone }}
      </template>

      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="formatStatus(item.ativo) == 'Ativo' ? 'green' : 'red'"
          dark
        >
          {{ formatStatus(item.ativo) }}
        </v-chip>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn icon v-can-access="42" @click="editItem(item)" color="orange" dark>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>-->
        <IconBottom :name="'edit'" v-can-access="42" @click="editItem(item)" />
        <v-btn
          v-can-access="1563"
          icon
          @click="deleteItem(item)"
          class="ml-2 white--text"
          color="red"
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
        <v-btn
          v-can-access="1292"
          icon
          @click="vincularPermissao(item)"
          class="ml-2 white--text"
          color="green"
          small
        >
          <v-icon>mdi-lock-open-plus</v-icon>
        </v-btn>
        <v-btn
          v-can-access="1297"
          icon
          @click="vincularEmpresa(item)"
          class="ml-2"
          color="primary"
          small
        >
          <v-icon>mdi-domain-plus</v-icon>
        </v-btn>
      </template>
    </BaseTable>
    <ModalUsuario
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editUser="user"
      @close="refetch"
    />
    <ModalVinculoPermissao
      :dialogPermissions="dialogPermissions"
      :userData="userData"
      @closePermissions="dialogPermissions = false"
    />
    <ModalVinculoEmpresa
      :dialogDomain="dialogDomain"
      :userData="userData"
      @closeDomain="dialogDomain = false"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import IconBottom from "@/components/shared/bottons/IconBottom";
import serviceUser from "@/services/sistema";
import { mapActions, mapGetters } from "vuex";
import phoneMixin from "@/mixins/phoneMixin";
import ModalUsuario from "@/components/sistema/usuarios/ModalUsuario";
import ModalVinculoPermissao from "@/components/sistema/usuarios/ModalVinculoPermissao";
import ModalVinculoEmpresa from "@/components/sistema/usuarios/ModalVinculoEmpresa";
export default {
  name: "Usuarios",

  mixins: [phoneMixin],

  components: {
    BaseTable,
    ModalUsuario,
    ModalVinculoPermissao,
    ModalVinculoEmpresa,
    IconBottom
  },

  data() {
    return {
      search: "",
      loading: false,
      dialog: false,
      dialogPermissions: false,
      dialogDomain: false,
      labelBtn: "Salvar",
      infoUser: [],
      userData: {},
      user: {},
      headers: [
        { text: "nome", value: "nome" },
        { text: "CPF", value: "cpf" },
        { text: "Telefone", value: "telefone" },
        { text: "E-mail", value: "email" },
        { text: "Nível", value: "niveis" },
        { text: "Papel", value: "papeis_nome" },
        { text: "Status", value: "ativo", align: "center" },
        { text: "Ações", value: "acoes", align: "center" }
      ]
    };
  },

  watch: {
    getUsers() {
      this.infoUser = this.getUsers.data;
      return this.infoUser;
    }
  },

  computed: {
    ...mapGetters({
      getUsers: "sistema/getUsers"
    })
  },

  methods: {
    ...mapActions({
      fetchUser: "sistema/fetchUser",
      fetchPermissions: "sistema/fetchPermissions"
    }),

    formatStatus(item) {
      if (item == "S") item = "Ativo";
      if (item == "N") item = "Inativo";
      return item;
    },

    editItem(item) {
      this.user = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async deleteItem(item) {
      await this.$swal.confirm(
        "Deletar usuário",
        `Deseja deletar o usuário ${item.nome}?`
      );
      try {
        await serviceUser.deleteUsers(item.id_usuario);
        this.$notify({
          type: "success",
          text: "Usuário deletado"
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar usuário"
        });
      }
    },

    vincularPermissao(item) {
      this.dialogPermissions = true;
      this.userData = item;
    },

    vincularEmpresa(item) {
      this.dialogDomain = true;
      this.userData = item;
    },

    async refetch() {
      this.dialog = false;
      await this.fetchUser({ per_page: -1 });
    }
  },

  async mounted() {
    await this.fetchUser({ per_page: -1 });
  }
};
</script>
