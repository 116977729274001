<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Usuário" : "Cadastrar Usuário" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6" md="3" lg="4">
              <v-text-field
                label="Nome"
                v-model="formUser.nome"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="4">
              <v-text-field
                label="CPF"
                v-model="formUser.cpf"
                :rules="[rules.required]"
                v-mask="['###.###.###-##']"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="4">
              <v-text-field
                label="Telefone"
                v-model="formUser.telefone"
                v-mask="['(##) #####-####']"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="4">
              <v-text-field
                label="E-mail"
                v-model="formUser.email"
                :rules="[rules.required, rules.emailCometa]"
                placeholder="informe um e-mail @viacometa.com.br"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="4">
              <NewBaseSelect
                label="Nível"
                :items="niveis"
                item-text="niveis"
                item-value="id_niveis"
                :preSelected="selectedNivel"
                v-model="formUser.id_niveis"
                :rules="[rules.required]"
              ></NewBaseSelect>
              <!-- <v-text-field
                label="Nível"
                v-model="formUser.id_niveis"
                :rules="[rules.required]"
              >
              </v-text-field> -->
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="4">
              <NewBaseSelect
                label="Papel"
                :items="papeis"
                item-text="nome"
                item-value="id"
                :preSelected="selectedPapel"
                v-model="formUser.id_papeis"
                :rules="[rules.required]"
              ></NewBaseSelect>
              <!-- <v-text-field
                label="Papel"
                v-model="formUser.id_papeis"
                :rules="[rules.required]"
              >
              </v-text-field> -->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? editarUser() : cadastrarUser()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import serviceSistema from "@/services/sistema";
import NewBaseSelect from "@/components/shared/NewBaseSelect";
export default {
  name: "ModalUsuario",

  components: {
    NewBaseSelect
  },

  mixins: [rules],

  props: {
    dialog: {
      type: Boolean
    },

    labelBtn: {
      type: String
    },

    editUser: {
      type: Object
    }
  },

  data() {
    return {
      niveis: [],
      selectedNivel: {},
      papeis: [],
      selectedPapel: {},
      formUser: {
        nome: "",
        cpf: "",
        telefone: "",
        email: "",
        id_niveis: "",
        id_papeis: "",
        ativo: ""
      },
      maskedNumber: "",
      maskedCpf: "",
      defaultState: {
        nome: "",
        cpf: "",
        telefone: "",
        email: "",
        id_niveis: "",
        id_papeis: "",
        ativo: ""
      }
    };
  },

  watch: {
    maskedCpf(value) {
      this.formUser.cpf = value.replace(/[^\d]/g, "");
    },

    maskedNumber(value) {
      this.formUser.telefone = value.replace(/[^\d]/g, "");
    },

    editUser() {
      if (this.editUser) {
        this.formUser = this.editUser;

        this.selectedNivel = this.niveis.find(
          item => item.id_niveis == this.editUser.id_niveis
        );

        this.selectedPapel = this.papeis.find(
          item => item.id == this.editUser.id_papeis
        );
      }
    }
  },

  methods: {
    async getAllNiveis() {
      try {
        const { data } = await serviceSistema.getNiveis({ per_page: -1 });
        this.niveis = data.data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro buscar menus"
        });
      }
    },

    async getAllPapeis() {
      try {
        const { data } = await serviceSistema.getPapeis({ per_page: -1 });
        this.papeis = data.data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro buscar menus"
        });
      }
    },

    async cadastrarUser() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        try {
          this.formUser.cpf = this.formUser.cpf.replace(/[^\d]/g, "");
          this.formUser.telefone = this.formUser?.telefone?.replace(
            /[^\d]/g,
            ""
          );
          await serviceSistema.postUsers(this.formUser),
            this.$notify({
              type: "success",
              text: "Usuário adicionado"
            });
        } catch (error) {
          this.$notify({
            type: "error",
            text: "Erro ao adicionar usuário"
          });
        } finally {
          this.close();
        }
      } else {
        this.$notify({
          type: "error",
          text: "Formulário inválido"
        });
      }
    },

    async editarUser() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        try {
          this.formUser.cpf = this.formUser.cpf.replace(/[^\d]/g, "");
          this.formUser.telefone = this.formUser?.telefone.replace(
            /[^\d]/g,
            ""
          );
          await serviceSistema.putUsers(
            this.formUser.id_usuario,
            this.formUser
          ),
            this.$notify({
              type: "success",
              text: "Usuário editado"
            });
        } catch (error) {
          this.$notify({
            type: "error",
            text: "Erro ao editar Usuário"
          });
        } finally {
          this.close();
        }
      } else {
        this.$notify({
          type: "error",
          text: "Formulário inválido"
        });
      }
    },

    close() {
      this.formUser = this.defaultState;
      this.selectedNivel = {};
      this.selectedPapel = {};
      this.$emit("close");
      this.$refs.form.reset();
    }
  },

  mounted() {
    this.getAllNiveis();
    this.getAllPapeis();
  }
};
</script>

<style></style>
