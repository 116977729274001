<template>
  <v-container class="container-card">
    <v-card>
      <Usuarios />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Usuarios from "@/components/sistema/usuarios/Usuarios.vue";

export default {
  name: "ConsultaUsuarios",

  components: {
    Usuarios
  },

  data() {
    return {
      tab: null
    };
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Usuário");
  }
};
</script>

<style scoped>
.v-tab--active {
  background-color: white;
}
</style>
